import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import { CircularProgress } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import React from "react";
import { useQuery } from "react-query";
import {
  dashboard_reseller_function,
  getJilliManagerInfo,
} from "../../Services";
import { AddBusiness } from "@mui/icons-material";
const Dashboard = () => {

  const usertype = localStorage.getItem("user_type")
  const { isLoading, data: dashboard_data } = useQuery(
    ["dashboard"],
    () => dashboard_reseller_function(),
    {
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus:false
    }
  );
  const dashboard_new_data = dashboard_data?.data?.data?.[0];
  const { isLoading: jilli_loder, data: jilli_info } = useQuery(
    ["jilli_info"],
    () => getJilliManagerInfo(),
    {
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus:false

    }
  );
  const jilli_info_data = jilli_info?.data?.data || {};

  const data = [
    {
      id: 1,
      item: "Manager",
      icon: <PersonPinIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />,
      count: usertype==="Master Distributer" ? jilli_info_data?.manager?.managerName : dashboard_new_data?.manager_id || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 2,
      item: "Total Coins",
      icon: (
        <AccountBalanceWalletIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count:  jilli_info_data?.manager?.money || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 4,
      item: "Virtual Coins",
      icon: (
        <SportsKabaddiIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: (Number(dashboard_data?.data?.sub_data?.[0]?.c_total_coins)?.toFixed(0, 2)) || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 3,
      item: "Total Game",
      icon: (
        <SportsVolleyballIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.total_game || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 4,
      item: "Total Distributor",
      icon: (
        <SportsKabaddiIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: (Number(dashboard_data?.data?.sub_data?.[0]?.c_benefited_distributor)?.toFixed(0, 2)) || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 5,
      item: "Total Reseller",
      icon: <AddBusiness className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />,
      count: (Number(dashboard_data?.data?.sub_data?.[0]?.c_benefited_reseller)?.toFixed(0, 2)) || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 6,
      item: "Total Active Domain",
      icon: (
        <PersonPinCircleIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.act_domain_cnt || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 7,
      item: "Total Transaction",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.trans_cnt || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 8,
      item: "Total Deactive Domain",
      icon: (
        <PersonPinCircleIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.de_act_domain_cnt || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 9,
      item: "Total End User",
      icon: <PriceCheckIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />,
      count: dashboard_new_data?.end_user_cnt || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 10,
      item: "Total Domain",
      icon: (
        <CurrencyRubleIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.domain_cnt || 0,
      isMD: true,
      isD: true,
      isR: true
    },
    {
      id: 17,
      item: "Total Pending Coin Request",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.pending_coin_cnt || 0,
      isMD: true,
      isD: true,
      isR: false
    },
    {
      id: 18,
      item: "Total Pending Coins ",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: (Number(dashboard_new_data?.pending_coins)?.toFixed(0,2)) || 0,
      isMD: true,
      isD: true,
      isR: false
    },
    {
      id: 19,
      item: "Total Success Coin Request",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.success_coin_cnt || 0,
      isMD: true,
      isD: true,
      isR: false
    },
    {
      id: 20,
      item: "Total Rejected Coin Request",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.reject_coin_cnt || 0,
      isMD: true,
      isD: true,
      isR: false
    },
    {
      id: 11,
      item: "Total Company Coin Pending Request",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      
      count: (Number(dashboard_new_data?.all_total_pending_coin_cnt)?.toFixed(0,2)) || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 12,
      item: "Total Company Coin Approved Request",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.all_total_success_coin_cnt || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 13,
      item: "Total Company Coin Rejected Request",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: dashboard_new_data?.all_total_rejected_coin_cnt || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 14,
      item: "Total Company Coin Pending",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: (Number(dashboard_new_data?.all_total_pending_coin)?.toFixed(0,2)) || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 15,
      item: "Total Company Coin Approved",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: (Number(dashboard_new_data?.all_total_success_coin)?.toFixed(0,2)) || 0,
      isMD: true,
      isD: false,
      isR: false
    },
    {
      id: 16,
      item: "Total Company Coin Rejected",
      icon: (
        <CurrencyExchangeIcon className="!h-[5rem] !w-[5rem] !text-[#2a2785]" />
      ),
      count: (Number(dashboard_new_data?.all_total_rejected_coin)?.toFixed(0,2)) || 0,
      isMD: true,
      isD: false,
      isR: false
    },

  ]
  if (isLoading || jilli_loder)
    return (
      <div className="w-[100%] h-[100%] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  return (
    <div className="grid lg:!grid-cols-4 md:!grid-cols-3 sm:grid-cols-1 p-5 gap-[2%] gap-y-4 ">
      {data?.filter((i) => usertype === "Reseller" ? i?.isR : usertype === "Distributer" ? i.isD : i?.isMD)?.map((i, index) => {
        return (
          <div
            key={index}
            className="!text-center !bg-white !bg-opacity-50 !rounded-lg !py-5 !cursor-pointer "
          >
            <div className="!text-lg !font-bold">{i?.icon}</div>
            <p className="!font-bold">{i?.item}</p>
            <p className="!font-extrabold !text-blue-700 !text-lg">
              {i?.count}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;
