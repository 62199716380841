import { Login, Money, Person } from "@mui/icons-material";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import LockResetIcon from "@mui/icons-material/LockReset";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
const type = localStorage.getItem("user_type")
const superDistributerSubMockData = [
  "IP Address",
  "Coin Request"
  
]
const distributerSubMockData = [
  "IP Address",
  "Add Coin",
  "Coin History",
  "Coin Request"
]
const resellerSubMockData = [
  "Activate Address",
   "IP Address",
   "Add Coin",
  "Coin History"
]
const superDistributerMockData = [
  "Dashboard",
  "Profile",
  "Registration",
  "IP Address",
  "Customer",
  "End User List",
  "Transaction List",
  "Bank & UPI",
  "Coin",
  "Game List"

]
const distributerMockData = [
  "Dashboard",
  "Profile",
  "Registration",
  "IP Address",
  "Customer",
  "End User List",
  "Transaction List",
  "Bank & UPI",
  "Coin",
  "Game List"
]
const resellerMockData = [
  "Dashboard",
  "Profile",
  "IP Address",
  "End User List",
  "Transaction List",
  "Coin",
  "Game List"
]

export const all_Data = [
  {
    id: 1,
    navLink: "/dashboard",
    navItem: "Dashboard",
    navIcon: (
      <span>
        <DashboardCustomizeIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [],
  },
  {
    id: 1.0,
    navLink: "/signup",
    navItem: "Registration",
    navIcon: (
      <span>
        <Login color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [],
  },
  {
    id: 2,
    navLink: "/customer",
    navItem: "Customer",
    navIcon: (
      <span>
        <SportsEsportsIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [
      {
        id: 2.2,
        navLink: "/customer",
        navItem: "All Customer",
        navIcon: (
          <span>
            <Diversity1Icon color="#15317E" fontSize="medium" />
          </span>
        ),
      },

    ],
  },
  {
    id: 3,
    navLink: "/iplist",
    navItem: "IP Address",
    navIcon: (
      <span>
        <SportsEsportsIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [
      {
        id: 2.2,
        navLink: "/iplist",
        navItem: "IP Address",
        navIcon: (
          <span>
            <Diversity1Icon color="#15317E" fontSize="medium" />
          </span>
        ),
      },
      {
        id: 2.3,
        navLink: "/addipaddress",
        navItem: "Activate Address",
        navIcon: (
          <span>
            <AddToPhotosIcon color="#15317E" fontSize="medium" />
          </span>
        ),
      },
    ]?.filter((i) =>
      type === "Master Distributer" ? superDistributerSubMockData?.includes(i?.navItem) :
        type === "Distributer" ? distributerSubMockData?.includes(i?.navItem) :
          type === "Reseller" && resellerSubMockData?.includes(i?.navItem)
    )
  },
  {
    id: 4,
    navLink: "/coin",
    navItem: "Coin",
    navIcon: (
      <span>
        <SportsEsportsIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [
      {
        id: 4.3,
        navLink: "/addcoin",
        navItem: "Add Coin",
        navIcon: (
          <span>
            <Diversity1Icon color="#15317E" fontSize="medium" />
          </span>
        ),
      },
      {
        id: 4.2,
        navLink: "/coin",
        navItem: "Coin History",
        navIcon: (
          <span>
            <Diversity1Icon color="#15317E" fontSize="medium" />
          </span>
        ),
      },
      {
        id: 4.3,
        navLink: "/resellerequest",
        navItem: "Coin Request",
        navIcon: (
          <span>
            <Diversity1Icon color="#15317E" fontSize="medium" />
          </span>
        ),
      },
    ]?.filter((i) =>
      type === "Master Distributer" ? superDistributerSubMockData?.includes(i?.navItem) :
        type === "Distributer" ? distributerSubMockData?.includes(i?.navItem) :
          type === "Reseller" && resellerSubMockData?.includes(i?.navItem)
    )
  },
  {
    id: 5,
    navLink: "/userlist",
    navItem: "End User List",
    navIcon: (
      <span>
        <LockResetIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [],
  },
  {
    id: 6,
    navLink: "/transactionlist",
    navItem: "Transaction List",
    navIcon: (
      <span>
        <Money color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [],
  },
  {
    id: 7,
    navLink: "/addbank",
    navItem: "Bank & UPI",
    navIcon: (
      <span>
        <LockResetIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [
      {
        id: 2.1,
        navLink: "/addbank",
    navItem: "Add Bank & UPI",
    navIcon: (
      <span>
        <LockResetIcon color="#15317E" fontSize="medium" />
      </span>
        ),
      },
      {
        id: 2.2,
        navLink: "/banklist",
        navItem: "Bank & UPI List",
        navIcon: (
          <span>
            <Diversity1Icon color="#15317E" fontSize="medium" />
          </span>
        ),
      },
     
    ],
  },
  {
    id: 18,
    navLink: "/profile",
    navItem: "Profile",
    navIcon: (
      <span>
        <Person color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [],
  },
  {
    id: 6,
    navLink: "/games/game-list",
    navItem: "Game List",
    navIcon: (
      <span>
        <LockResetIcon color="#15317E" fontSize="medium" />
      </span>
    ),
    subcomponent: [],
  },
]?.filter((j) =>
  type === "Master Distributer" ? superDistributerMockData?.includes(j?.navItem) :
    type === "Distributer" ? distributerMockData?.includes(j?.navItem) :
      type === "Reseller" && resellerMockData?.includes(j?.navItem)
)


