import { CircularProgress } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { getGameListForReseller } from "../../Services";
const GameList = () => {
  const { isLoading, data: dashboard_data } = useQuery(
    ["get_game_list"],
    () => getGameListForReseller(),
    {
      refetchOnMount: false,
      refetchOnReconnect: true,
    }
  );
  const dashboard_new_data = dashboard_data?.data?.data || [];
  if (isLoading)
    return (
      <div className="w-[100%] h-[100%] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  return (
    <div className="grid lg:!grid-cols-4 md:!grid-cols-3 sm:grid-cols-1 p-5 gap-[2%] gap-y-4 ">
      {dashboard_new_data?.map((i, index) => {
        return (
          <div
            key={index}
            className="!text-center !bg-white !bg-opacity-50 !rounded-lg !py-5 !cursor-pointer"
          >
            <img className="!text-lg !font-bold" src={i?.img} alt=""/>
            <p className="!font-bold">{i?.name}</p>
            <p className="!font-extrabold !text-blue-700 !text-lg">
              {i?.count}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default GameList;
