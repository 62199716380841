import { FilterAlt } from "@mui/icons-material";
import { Button, Switch, TextField } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import { toast } from "react-toastify";

const IPList = () => {
    const [loading, setloding] = useState(false)
    const [data, setData] = useState([])
    const initialValue = {
        username: "",
        start_date: "",
        end_date: ""
    }
    const fk = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: () => {
            setloding(true)
            const reqBody = {
                username: fk.values.username,
                start_date: fk.values.start_date,
                end_date: fk.values.end_date
            }
            IPAdressFn(reqBody)
        }
    })

    const IPAdressFn = async (reqBody) => {
        setloding(true)
        try {
            const response = await axiosInstance.post(API_URLS.get_ip_list, reqBody)
            setData(response?.data?.data || [])
            setloding(false);
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        IPAdressFn()
    }, [])
    const changeIPStatusFunction = async (id) => {
        try {
            const res = await axiosInstance.get(
                API_URLS?.change_ip_status + id);
            toast(res?.data?.msg)
            const reqBody = {
                username: fk.values.username,
                start_date: fk.values.start_date,
                end_date: fk.values.end_date
            }
            if (res) IPAdressFn(reqBody);
        } catch (e) {
            console.log(e);
        }
    };

    const tablehead = [
        <span>S.No</span>,
        <span>Domain IP</span>,
        <span>Domain Url</span>,
        <span>Customer Id</span>,
        <span>Date</span>,
        <span>Status</span>
    ];

    const tablerow = data?.map((i, index) => {
        return [
            <span>{index + 1}</span>,
            <span>{i?.do_domain_ip}</span>,
            <span>{i?.do_domain_url}</span>,
            <span>{i?.lo_cust_id}</span>,
            <span>{moment(i?.do_created_at)?.format("YYYY-MM-DD")}</span>,
            <span>

                <Switch
                    onClick={() => {
                        changeIPStatusFunction(i?.do_id);
                    }} checked={i?.do_domain_status === "Deactive" ? false : true} />

            </span>,
        ];
    });
    return (
        <div>
            <div className="flex px-2 gap-5 !justify-start py-2">
                <span className="font-bold !text-center">From:</span>
                <TextField
                    size="small"
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={fk.values.start_date}
                    onChange={fk.handleChange}
                />
                <span className="font-bold !text-center">To:</span>
                <TextField
                    size="small"
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={fk.values.end_date}
                    onChange={fk.handleChange}
                />
                <TextField
                    size="small"
                    type="search"
                    id="username"
                    name="username"
                    placeholder="Search by user id"
                    value={fk.values.username}
                    onChange={fk.handleChange}
                />
                <Button
                    onClick={fk.handleSubmit}
                    variant="contained"
                    startIcon={<FilterAlt />}
                >
                    Filter
                </Button>
            </div>

            <CustomTable
                tablehead={tablehead}
                tablerow={tablerow}
                isLoading={loading}
            />

        </div>
    );
};

export default IPList;
