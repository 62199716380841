
import DistributorSignUp from "../Authentication/DistributorRegistration";
import BankUpi from "../Pages/Bank/AddBankUPi";
import BankList from "../Pages/Bank/BankList";
import DistributerReseller from "../Pages/Bank/DistributerResellerRequest";
import Changepassword from "../Pages/changepassword/Changepassword";
import AddCoin from "../Pages/Coin/AddCoinList";
import CoinList from "../Pages/Coin/CoinList";
import CustomerList from "../Pages/Customer/Customerlist";
import UpdateCustomer from "../Pages/Customer/UpdateCustomer";
import Dashboard from "../Pages/dashboard/Dashboard";
import ProfileList from "../Pages/dashboard/Profile";
import EndUserList from "../Pages/EndUSer/Enduserlist";
import Fund from "../Pages/fund/Fund";
import FundHistory from "../Pages/fund/FundHistory";
import GameList from "../Pages/games/GameList";
import ApprovedRequest from "../Pages/gamewithdrawlrequest/ApprovedRequest";
import GameWithdrawlRequest from "../Pages/gamewithdrawlrequest/GameWithdrawlRequest";
import RejectRequest from "../Pages/gamewithdrawlrequest/RejectRequest";
import DownLine from "../Pages/genealogy/DownLine";
import Genealogy from "../Pages/genealogy/Genealogy";
import AddipAddress from "../Pages/IPAddress/AddIpAddress";
import IPList from "../Pages/IPAddress/IpList";
import AddPlayer from "../Pages/player/AddPlayer";
import Player from "../Pages/player/Player";
import TransactionList from "../Pages/transactionlist/Transaction";

export const routes = [ 
  {
    id: 1,
    path: "/dashboard",
    component: <Dashboard />,
    navItem: "Dashboard",
  },
  {
    id: 2,
    path: "/signup",
    component: <DistributorSignUp />,
    navItem: "Registration",
  },
  {
    id: 3,
    path: "/customer",
    component: <CustomerList />,
    navItem: "All Customer",
  },
  {
    id: 4,
    path: "/customer/update-customer",
    component: <UpdateCustomer />,
    navItem: "Update Customer",
  },
  {
    id: 5,
    path: "/iplist",
    component: <IPList />,
    navItem: "IP Address",
  },
  {
    id: 5,
    path: "/addipaddress",
    component: <AddipAddress />,
    navItem: "Activate IP Address",
  },
  {
    id: 3,
    path: "/player",
    component: <Player />,
    navItem: "Player",
  },
  {
    id: 4,
    path: "/userlist",
    component: <EndUserList/>,
    navItem: "End User List",
  },
  {
    id: 411,
    path: "/transactionlist",
    component: <TransactionList/>,
    navItem: "Transaction List",
  },
  {
    id: 5,
    path: "/game-withdrawl-request",
    component: <GameWithdrawlRequest />,
    navItem: "Game Withdrawl Request",
  },
  {
    id: 6,
    path: "/genealogy",
    component: <Genealogy />,
    navItem: "Direct",
  },
  {
    id: 7,
    path: "/genealogy/downline",
    component: <DownLine />,
    navItem: "Downline",
  },
  
  {
    id: 9,
    path: "/fund",
    component: <Fund/>,
    navItem: "Fund",
  },
  {
    id: 10,
    path: "/player/add-player",
    component: <AddPlayer/>,
    navItem: "Add Player",
  },
  {
    id: 11,
    path: "/change-password",
    component: <Changepassword/>,
    navItem: "Change Password",
  },
  {
    id: 12,
    path: "/game-withdrawl-request/approved-request",
    component: <ApprovedRequest/>,
    navItem: "Approved Request",
  },
  {
    id: 13,
    path: "/game-withdrawl-request/reject-request",
    component: <RejectRequest/>,
    navItem: "Reject Request",
  },
  {
    id: 26,
    path: "/fund/transfer-fund-history",
    component: <FundHistory/>,
    navItem: "Fund History",
  },
  {
    id: 27,
    path: "/addbank",
    component: <BankUpi/>,
    navItem: "Add Bank & Upi",
  },
  {
    id: 28,
    path: "/banklist",
    component: <BankList/>,
    navItem: "Bank List",
  },
  {
    id: 29,
    path: "/games/game-list",
    component: <GameList/>,
    navItem: "Game List",
  },
  {
    id: 30,
    path: "/coin",
    component: <CoinList/>,
    navItem: "Coin History",
  },
  {
    id: 30,
    path: "/addcoin",
    component: <AddCoin/>,
    navItem: "Add Coin",
  },
  {
    id: 31,
    path: "/resellerequest",
    component: <DistributerReseller/>,
    navItem: "Coin Request",
  },
  {
    id: 32,
    path: "/profile",
    component: <ProfileList/>,
    navItem: "Profile",
  },
];

// UpdatePermissions