import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { API_URLS } from "../config/APIUrls";
import axiosInstance from "../config/axios";
import { signupSchemaValidataon } from "../Services/FormValidation";
import CustomCircularProgress from "../Shared/CustomDialogBox";



const DistributorSignUp = () => {
  const [loding, setloding] = useState(false);
  const type = localStorage.getItem("user_type")
  const initialValue = {
    email: "",
    mobile: "",
    f_name: "",
    l_name: "",
    pass: "",
    confirmpass: "",
    u_user_type: "1",
  };

  const fk = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: signupSchemaValidataon,
    onSubmit: () => {
      if (fk.values.pass !== fk.values.confirmpass)
        return toast("Password and confirm password should be same.");
     
      const reqBody = {
        email: fk.values.email,
        mobile: String(fk.values.mobile) || "",
        pass: fk.values.pass,
        confirmpass: fk.values.confirmpass,
        l_name: fk.values.l_name,
        f_name: fk.values.f_name,
        u_user_type: fk.values.u_user_type

      }
      signupFunction(reqBody);
    },
  });
  const signupFunction = async (reqBody) => {
    setloding(true);
    try {
      const response = await axiosInstance.post(API_URLS.sign, reqBody);
      toast(response?.data?.msg)
      if ("Registration Successfully." === response?.data?.msg) {   
        fk.handleReset();
      }
    } catch (e) {
      console.log(e);
    }
    setloding(false);
  }

  return (

    <div className="w-[100%]   flex justify-center items-center">
      <div className=" lg:w-full p-4">
        <p className="!text-center font-bold !py-4 text-lg">Registration</p>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-[6%] gap-y-8 pt-5 w-full">
          <div className="">
            <p className="font-bold">First Name</p>
            <TextField
              fullWidth
              id="f_name"
              name="f_name"
              placeholder="First Name"
              value={fk.values.f_name}
              onChange={fk.handleChange}
            />
            {fk.touched.f_name && fk.errors.f_name && (
              <div className="error">{fk.errors.f_name}</div>
            )}
          </div>
          <div className="">
            <p className="font-bold">Last Name</p>
            <TextField
              fullWidth

              id="l_name"
              name="l_name"
              placeholder="Last Name"
              value={fk.values.l_name}
              onChange={fk.handleChange}
            />
            {fk.touched.l_name && fk.errors.l_name && (
              <div className="error">{fk.errors.l_name}</div>
            )}
          </div>
          <div className="">
            <p className="font-bold"> Phone number</p>
            <TextField
              fullWidth

              type="number"
              id="mobile"
              name="mobile"
              placeholder="Phone Number"
              value={fk.values.mobile}
              onChange={fk.handleChange}
            />
            {fk.touched.mobile && fk.errors.mobile && (
              <div className="error">{fk.errors.mobile}</div>
            )}
          </div>
          <div className="">
            <p className="font-bold">Email</p>
            <TextField
              fullWidth

              id="email"
              name="email"
              placeholder="Email"
              value={fk.values.email}
              onChange={fk.handleChange}
            />
            {fk.touched.email && fk.errors.email && (
              <div className="error">{fk.errors.email}</div>
            )}
          </div>
          <div>
            <p className="font-bold">Set Password</p>
            <TextField
              fullWidth

              id="pass"
              name="pass"
              placeholder="Set Password"
              value={fk.values.pass}
              onChange={fk.handleChange}
            />
            {fk.touched.pass && fk.errors.pass && (
              <div className="error">{fk.errors.pass}</div>
            )}
          </div>
          <div className="">
            <p className="font-bold">Confirm password</p>
            <TextField
              fullWidth

              id="confirmpass"
              name="confirmpass"
              placeholder="Confirm Password"
              value={fk.values.confirmpass}
              onChange={fk.handleChange}
            />
            {fk.touched.confirmpass && fk.errors.confirmpass && (
              <div className="error">{fk.errors.confirmpass}</div>
            )}
          </div>
          {type === "Master Distributer" ? <div>
            <p className="font-bold ">Type</p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={fk.values.u_user_type}
                onChange={(e) => fk.setFieldValue("u_user_type", e.target.value)}
              >
                <Box display="flex" flexDirection="row" gap={2}>
                  <FormControlLabel value="1" control={<Radio />} label="Reseller" />
                  <FormControlLabel value="2" control={<Radio />} label="Distributor" />
                </Box>
              </RadioGroup>
            </FormControl>

          </div> : <div>
            <p className="font-bold ">Type</p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={fk.values.u_user_type}
                onChange={(e) => fk.setFieldValue("u_user_type", e.target.value)}
              >
                <Box display="flex" flexDirection="row" gap={2}>
                  <FormControlLabel value="1" control={<Radio />} checked={true} label="Reseller" />
                </Box>
              </RadioGroup>
            </FormControl></div>}
          <CustomCircularProgress isLoading={loding} />
        </div>
        <div className="flex justify-end  gap-3">
          <Button
            onClick={() => fk.handleReset()}
            variant="contained"
            className="!bg-[#E74C3C]"
          >
            Clear
          </Button>
          <Button
            onClick={() => fk.handleSubmit()}
            variant="contained"
            className="!bg-[#07BC0C]"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DistributorSignUp;
