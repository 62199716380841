import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import { API_URLS } from "../../config/APIUrls";

const BankList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const BankListFn = async () => {
        try {
            const response = await axiosInstance.get(API_URLS.list_bank_upi_details);
            setData(response?.data?.data || []);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        BankListFn();
    }, []);

    return (
        <div>
            <div className="!flex justify-center items-center w-full">
                <div className="p-5 w-full rounded-lg">
                    <p className="!text-center font-bold !py-4 !pb-10 text-lg">Bank / UPI Details</p>
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:gid-cols-2 gap-[6%] gap-y-4">
                        {data?.map((i) => {
                            return (
                                <>
                                    {i?.m_d_type === "Bank" && (
                                        <>
                                            <div>
                                                <p className="font-bold mt-5">
                                                    Bank Detail:{" "}
                                                    <Edit
                                                        className="!text-green-500"
                                                        onClick={() =>
                                                            navigate("/addbank", {
                                                                state: {
                                                                    m_d_type: "1",
                                                                    m_d_bank_name: i?.m_d_bank_name,
                                                                    m_d_ifsc_code: i?.m_d_ifsc_code,
                                                                    m_d_account_no: i?.m_d_account_no,
                                                                    m_d_holder_name: i?.m_d_holder_name,
                                                                    m_d_mobile_no: i?.m_d_mobile_no,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">Bank Name</p>
                                                <p className="!border !text-center !border-gray-500 p-2 !rounded">
                                                    {i?.m_d_bank_name}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">IFSC Code</p>
                                                <p className="!border !text-center !border-gray-500 p-2 !rounded">
                                                    {i?.m_d_ifsc_code}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">Account No</p>
                                                <p className="!border !text-center !border-gray-500 p-2 !rounded">
                                                    {i?.m_d_account_no}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">Account Holder Name</p>
                                                <p className="!border !text-center !border-gray-500 p-2 !rounded">
                                                    {i?.m_d_holder_name}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">Mobile No</p>
                                                <p className="!border !text-center !border-gray-500 p-2 !rounded">
                                                    {i?.m_d_mobile_no}
                                                </p>
                                            </div>
                                        </>
                                    )}

                                    {i?.m_d_type === "UPI" && (
                                        <>
                                            <div>
                                                <p className="font-bold mt-5">
                                                    UPI Detail:{" "}
                                                    <Edit
                                                        className="!text-green-500"
                                                        onClick={() =>
                                                            navigate("/addbank", {
                                                                state: {
                                                                    m_d_type:"2",
                                                                    m_d_upi_id: i?.m_d_upi_id,
                                                                    m_d_qr_image: i?.m_d_qr_image,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">UPI ID</p>
                                                <p className="!border !text-center !border-gray-500 p-2 !rounded">
                                                    {i?.m_d_upi_id}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-bold !mb-2">QR Code</p>
                                                <img src={i?.m_d_qr_image} alt="QR Code" className="h-60 w-72"/>
                                            </div>
                                        </>
                                    )}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankList;
