import { useFormik } from "formik";
import React, { useState } from "react";
import { AiFillLock, AiOutlineMail } from "react-icons/ai";
import bg from "../../Assets/bg.png";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import CustomCircularProgress from "../../Shared/loder/CustomCircularProgress";
import QRScreen from "./QRScreen";

const LogIn = () => {
  const [loding, setloding] = useState(false);
  const [Openbox, setBox] = useState(false);
  const [data, setData] = useState("");
  const initialValue = {
    username: "",
    password: "",
    otp:""
  };

  const fk = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: () => {
      const reqBody = {
        username: fk.values.username,
        password: fk.values.password,
        otp: "",
      };
      loginFunction(reqBody);
    },
  });
  const loginFunction = async (reqBody) => {
    setloding(true);
    try {
      const response = await axiosInstance.post(API_URLS.login, reqBody);
      setBox(true);
      setData(response?.data?.qrCodeUrl);
      setloding(false);
    } catch (e) {
      console.log(e);
    }
    setloding(false);
  };
  return (
     Openbox ?  <QRScreen qrscreenimage={data} newfk={fk}/> :
       <div className="lg:flex lg:w-screen lg:relative">
        <img
          src={bg}
          className="lg:block hidden w-full h-screen object-fill lg:absolute"
          alt=""
        />
        <div className="lg:block hidden">
          <div className="absolute h-screen bg-red lg:w-[38%] left-0 top-0  text-white flex items-center justify-center"></div>
        </div>
        <CustomCircularProgress isLoading={loding} />
        <div className="lg:flex justify-end h-screen lg:w-[40%] w-[90%]  absolute right-4 top-0 overflow-auto z-20 ">
          <div className="w-full flex flex-col items-center  gap-1  h-full">
            <div className="flex flex-col  items-center rounded-2xl w-full h-[80%] justify-evenly">
              <p className="text-[1rem] lg:hidden  font-bold  font-poppins">
              Log In{" "}
              </p>
              <p className="lg:block hidden !font-bold !text-xl"> Log In </p>
              <div className="lg:w-[70%] w-full mt-5">
                <label className="text-gray-500 ">User Name:</label>
                <br />
                <div className="flex items-center ">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={fk.values.username}
                    onChange={fk.handleChange}
                    placeholder="Enter your UserName"
                    className="w-full h-14 focus:outline-none  bg-[#E8F0FE] rounded-l-lg px-3 py-2 border border-r-0 border-t-0 border-l-0 tracking-wider "
                  />
                  <div className="bg-[#E8F0FE]">
                    <p
                      className="bg-[#1E2772] text-white h-14 w-14  flex justify-center
                     items-center text-[1.5rem] rounded-xl"
                    >
                      <AiOutlineMail />
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-[70%] w-full">
                <label className="text-gray-500">Password:</label>
                <br />
                <div className="flex items-center">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={fk.values.password}
                    onChange={fk.handleChange}
                    placeholder="Enter your password here"
                    className="w-full h-14 focus:outline-none bg-[#E8F0FE] 
                    rounded-l-lg px-3 py-2 border border-r-0 border-t-0 border-l-0 tracking-wider"
                  />
                  <div className="bg-[#E8F0FE]">
                    <p className="bg-[#1E2772] text-white h-14 w-14  flex justify-center items-center text-[1.5rem] rounded-xl">
                      <AiFillLock />
                    </p>
                  </div>
                </div>
                {/* <div className="w-full mt-5 flex justify-end hover:text-blue-800 cursor-pointer ">
                  <p className="text-sm font-poppins ">Forgot Password?</p>
                </div> */}
              </div>
              <div className="lg:w-[70%] w-full">
                <button
                  type="submit"
                  size="large"
                  className="w-full z-20 shadow-md cursor-pointer bg-[#1E2772] !rounded-lg !px-10 py-2 text-white "
                  onClick={fk.handleSubmit}
                >
                 2F Authentication
                </button>
              </div>
              {/* <div
                className="hover:text-blue-800 cursor-pointer"
                onClick={() => navigate("/sign")}
              >
                Don't have an account?
              </div> */}
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default LogIn;
