import { Edit, FilterAlt } from "@mui/icons-material";
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Switch, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import { toast } from "react-toastify";

const CustomerList = () => {
    const navigate = useNavigate()
    const usertype = localStorage.getItem("user_type")
    const [loading, setloding] = useState(false)
    const [data, setData] = useState([])
    const initialValue = {
        username: "",
        start_date: "",
        end_date: "",
        record_type: "2"
    }
    const fk = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: () => {
            setloding(true)
            const reqBody = {
                username: fk.values.username,
                start_date: fk.values.start_date,
                end_date: fk.values.end_date,
                record_type: fk.values.record_type
            }
            CustomerFn(reqBody)
        }
    })
    const CustomerFn = async (reqBody) => {
        setloding(true)
        try {
            const response = await axiosInstance.post(API_URLS.customer, reqBody)
            setData(response?.data?.data || [])
            setloding(false);
        }
        catch (e) {
            console.log(e)
        }
    }

    const Customerstatusfn = async (id) => {
        const reqBody = {
            u_user_id: id
        }
        try {
            const res = await axiosInstance.post(API_URLS.customer_status,
                reqBody
            )
            toast(res?.data?.msg)
            if (res?.data?.msg === "IP Status Updated Successfully.") {
                CustomerFn()
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        CustomerFn()
    }, [])



    const tablehead = [
        <span>S.No</span>,
        <span>Action</span>,
        <span>First Name</span>,
        <span>Last Name</span>,
        <span>Mobile</span>,
        <span>Email</span>,
        <span>Domain Count</span>,
        <span>Type</span>,
        <span>Customer ID</span>,
        <span>Parent ID</span>,
        <span>Coin Rate</span>,
        <span>Status</span>,
    ];

    const tablerow = data?.map((i, index) => {
        return [
            <span>{index + 1}</span>,
            <span><Edit onClick={() =>
                navigate("/customer/update-customer", {
                    state: {
                        c_lo_id: i?.c_lo_id,
                        c_first_name: i?.c_first_name,
                        c_last_name: i?.c_last_name,
                        c_mobile: i?.c_mobile,
                        c_email: i?.c_email,
                        c_status: i?.c_status,
                        c_coin_rate: i?.c_coin_rate,
                    },
                })
            }
                className="!text-green-600" /></span>,
            <span>{i?.c_first_name}</span>,
            <span>{i?.c_last_name}</span>,
            <span>{i?.c_mobile}</span>,
            <span>{i?.c_email}</span>,
            <span>{i?.c_domain_count}</span>,
            <span>{i?.lo_type}</span>,
            <span>{i?.lo_cust_id}</span>,
            <span>{i?.parent_id}</span>,
            <span>{Number(i?.c_coin_rate)?.toFixed(2)}</span>,
            <span>
                <Switch onClick={(() => Customerstatusfn(i?.c_lo_id))} checked={i?.c_status === "Deactive" ? false : true} />
            </span>,
        ];
    });
    return (
        <div>
            <div className="flex px-2 gap-2 !justify-start py-2">
                <span className="!text-xs !text-center">From:</span>
                <TextField
                    size="small"
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={fk.values.start_date}
                    onChange={fk.handleChange}
                />
                <span className="!text-xs !text-center">To:</span>
                <TextField
                    size="small"
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={fk.values.end_date}
                    onChange={fk.handleChange}
                />
                <TextField
                    size="small"
                    type="search"
                    id="username"
                    name="username"
                    placeholder="Search by user id"
                    value={fk.values.username}
                    onChange={fk.handleChange}
                />
                {usertype === "Master Distributer" &&
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={fk.values.record_type}
                            onChange={(e) => fk.setFieldValue("record_type", e.target.value)}
                        >
                            <Box display="flex" flexDirection="row" gap={1}>
                                <FormControlLabel value="2" control={<Radio />} label="All Record" />
                                <FormControlLabel value="1" control={<Radio />} label="Our Record" />
                            </Box>

                        </RadioGroup>
                    </FormControl>}

                <Button
                    onClick={fk.handleSubmit}
                    variant="contained"
                    startIcon={<FilterAlt />}
                >
                    Filter
                </Button>
            </div>

            <CustomTable
                tablehead={tablehead}
                tablerow={tablerow}
                isLoading={loading}
            />

        </div>
    );
};

export default CustomerList;
