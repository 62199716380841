import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import ava from "../../Assets/person.png";

const ProfileList = () => {
    const [data, setData] = useState([]);
    const [parent, setParentData] = useState([]);
    const user = localStorage.getItem("user_type")

    const ProfileListFn = async () => {
        try {
            const response = await axiosInstance.get(API_URLS.profile_api);
            setData(response?.data?.data?.own_dat);
            setParentData(response?.data?.data?.parent_dat);
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        ProfileListFn();
    }, []);

    return (
        <>
            <div className="!flex justify-center p-2 pt-5">
                <Avatar alt="" src={ava} className="!h-28 !w-28" />
            </div>
            <div className="!flex lg:flex-row flex-col text-lg p-2 pt-4 justify-center gap-10 ">
                <div>
                    {user !== "Master Distributer" && (
                        <div className="">
                            <p className="text-xl !font-bold my-4">Sponser Data</p>
                            <p>Name : {parent?.c_first_name} {parent?.c_last_name}</p>
                            <p>Email : {parent?.c_email}</p>
                            <p>Mobile No. : {parent?.c_mobile}</p>
                            <p>ID : {parent?.lo_cust_id}</p>
                        </div>
                    )}
                </div>
                <div className="flex flex-col  gap-2 justify-center ">
                    <p className="">Name : {data?.c_first_name} {data?.c_last_name} </p>
                    <p className="">Mobile Number : {data?.c_mobile}</p>
                    <p className="">Manager ID :  <span className="!text-blue-800"> {data?.lo_cust_id}</span> </p>
                    {user === "Master Distributer" && (
                        <>
                            <p className="">Coin Rate : {Number(data?.c_coin_rate)?.toFixed(0, 2)}</p>
                        </>
                    )}
                    <p className="">Email :  {data?.c_email}</p>
                </div>
                <div className="flex flex-col  gap-2 justify-center ">
                    <p className="">User  : <span className="!text-blue-800">  {data?.lo_type} </span> </p>
                    {user === "Master Distributer" && (
                        <>
                            <p>Total Distributor : {Number(data?.c_benefited_distributor)?.toFixed(0, 2)}</p>
                            <p>Total Reseller : {Number(data?.c_benefited_reseller)?.toFixed(0, 2)}</p>
                        </>
                    )}
                    {user === "Distributer" && (
                        <p>Total Reseller : {Number(data?.c_benefited_reseller)?.toFixed(0, 2)}</p>
                    )}

                    {user !== "Reseller" && (
                        <p className="">Total Coins : {Number(data?.c_total_coins)?.toFixed(0, 2)}</p>

                    )}
                    <p className="">Used Coins : {Number(data?.c_used_coins)?.toFixed(0, 2)}</p>
                </div>
            </div >


        </>
    );
};

export default ProfileList;
