import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogBox = ({
    openCustomDialogBox,
    setOpenCustomDialogBox,
    component,
    title
}) => {

    const [open, setOpen] = React.useState(openCustomDialogBox);
    
   
  
    const handleClose = () => {
    //   setOpen(false);
    setOpenCustomDialogBox(false)
    };
  
  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{className:"!max-w-[1000px]"}}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
       
        >

          {component}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}

export default CustomDialogBox;