import { FilterAlt } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";

const EndUserList = () => {
    const [loading, setloding] = useState(false)
    const [data, setData] = useState([])
    const initialValue = {
        username: "",
        start_date: "",
        end_date: ""
    }
    const fk = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: () => {
            setloding(true)
            const reqBody = {
                username: fk.values.username,
                start_date: fk.values.start_date,
                end_date: fk.values.end_date
            }
            EndUserFn(reqBody)
        }
    })

    const EndUserFn = async (reqBody) => {
        setloding(true)
        try {
            const response = await axiosInstance.post(API_URLS.end_userdetails, reqBody)
           setData(response?.data?.data || [] )
            setloding(false);
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(()=>{
        EndUserFn()
    },[])

    const tablehead = [
        <span>S.No</span>,
        // <span>Action</span>,
        // <span>First Name</span>,
        <span> Name</span>,
        <span>Mobile</span>,
        <span>Email</span>,
        <span>Domain IP</span>,
        <span>Domain </span>,
        <span>Account ID</span>,
        <span>Date/Time</span>,
    ];

    const tablerow = data?.map((i, index) => {
        return [
            <span>{index+1}</span>,
            // <span><Edit onClick={() =>
            //     navigate("/customer/update-customer", {
            //       state: {
            //         c_lo_id: i?.c_lo_id,
            //         c_first_name:i?.c_first_name,
            //         c_last_name:i?.c_last_name,
            //         c_mobile:i?.c_mobile,
            //         c_email:i?.c_email,
            //         c_status:i?.c_status,
            //       },
            //     })
            //   }
            // className="!text-green-600"/></span>,
            <span>{i?.c_first_name} {i?.c_last_name} </span>,
            <span>{i?.end_user_mobile}</span>,
            <span>{i?.end_user_email}</span>,
            <span>{i?.do_domain_ip}</span>,
            <span>{i?.do_domain_url}</span>,
            <span>{i?.end_user_account_id}</span>,
            <span>
              {moment.utc(i?.end_user_created_at)?.format("YYYY-MM-DD HH:mm:ss")}
            </span>,
        ];
    });
    return (
        <div>
            <div className="flex px-2 gap-5 !justify-start py-2">
                <span className="font-bold !text-center">From:</span>
                <TextField
                size="small"
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={fk.values.start_date}
                    onChange={fk.handleChange}
                />
                <span className="font-bold !text-center">To:</span>
                <TextField
                size="small"
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={fk.values.end_date}
                    onChange={fk.handleChange}
                />
                <TextField
                size="small"
                    type="search"
                    id="username"
                    name="username"
                    placeholder="Search by user id"
                    value={fk.values.username}
                    onChange={fk.handleChange}
                />
                <Button
                    onClick={fk.handleSubmit}
                    variant="contained"
                    startIcon={<FilterAlt />}
                >
                    Filter
                </Button>
            </div>
           
            <CustomTable
                tablehead={tablehead}
                tablerow={tablerow}
                isLoading={loading}
            />
          
        </div>
    );
};

export default EndUserList;
