import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import CustomCircularProgress from "../../Shared/CustomDialogBox";

const BankUpi = () => {
  const [loding, setloding] = useState(false);
  const location = useLocation()
  const navigate = useNavigate();
  const initialValue = {
    m_d_type: location?.state?.m_d_type || "1",
    m_d_bank_name: location?.state?.m_d_bank_name || "",
    m_d_ifsc_code: location?.state?.m_d_ifsc_code || "",
    m_d_account_no: location?.state?.m_d_account_no || "",
    m_d_holder_name: location?.state?.m_d_holder_name || "",
    m_d_mobile_no: location?.state?.m_d_mobile_no || "",
    m_d_upi_id: location?.state?.m_d_upi_id || "",
    m_d_qr_image: location?.state?.m_d_qr_image || "",
  };

  const fk = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: () => {
      const reqBody = {
        m_d_type: fk.values.m_d_type,
        m_d_bank_name: fk.values.m_d_bank_name,
        m_d_ifsc_code: fk.values.m_d_ifsc_code,
        m_d_account_no: fk.values.m_d_account_no,
        m_d_holder_name: fk.values.m_d_holder_name,
        m_d_mobile_no: fk.values.m_d_mobile_no,
        m_d_upi_id: fk.values.m_d_upi_id,
        m_d_qr_image: fk.values.m_d_qr_image,
      };
      AddIPFunction(reqBody);
    },
  });

  const handleQrImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        fk.setFieldValue("m_d_qr_image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const AddIPFunction = async (reqBody) => {
    setloding(true);
    try {
      const response = await axiosInstance.post(
        API_URLS.add_bank_upi_details,
        reqBody
      );
      toast(response?.data?.msg);
      if ("Data Add Successfully." === response?.data?.msg) {
        fk.handleReset();
        navigate("/banklist");

      }
    } catch (e) {
      console.log(e);
    }
    setloding(false);
  };

  return (
    <div className="!flex justify-center items-center w-full">
      <div className="p-5 w-full rounded-lg">
        <p className="!text-center font-bold !py-4 !pb-10 text-lg">Add Bank / UPI</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:gid-cols-2 gap-[6%] gap-y-4">
          <div>
            <p> Type</p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={fk.values.m_d_type}
                onChange={(e) => fk.setFieldValue("m_d_type", e.target.value)}
              >
                <Box display="flex" flexDirection="row" gap={2}>
                  <FormControlLabel value="1" control={<Radio />} label="Bank" />
                  <FormControlLabel value="2" control={<Radio />} label="UPI" />
                </Box>
              </RadioGroup>
            </FormControl>
          </div>
          {fk.values.m_d_type === "1" && (
            <>
              <div>
                <p className="font-bold">Bank Name</p>
                <TextField
                  type="text"
                  fullWidth
                  id="m_d_bank_name"
                  name="m_d_bank_name"
                  placeholder="Bank Name"
                  value={fk.values.m_d_bank_name}
                  onChange={fk.handleChange}
                />
              </div>
              <div>
                <p className="font-bold">IFSC Code</p>
                <TextField
                  fullWidth
                  type="text"
                  id="m_d_ifsc_code"
                  name="m_d_ifsc_code"
                  placeholder="IFSC Code"
                  value={fk.values.m_d_ifsc_code}
                  onChange={fk.handleChange}
                />
              </div>
              <div>
                <p className="font-bold">Account No</p>
                <TextField
                  fullWidth
                  type="text"
                  id="m_d_account_no"
                  name="m_d_account_no"
                  placeholder="Account No"
                  value={fk.values.m_d_account_no}
                  onChange={fk.handleChange}
                />
              </div>
              <div>
                <p className="font-bold">Account Holder Name</p>
                <TextField
                  fullWidth
                  type="text"
                  id="m_d_holder_name"
                  name="m_d_holder_name"
                  placeholder="Account Holder Name"
                  value={fk.values.m_d_holder_name}
                  onChange={fk.handleChange}
                />
              </div>
              <div>
                <p className="font-bold">Mobile No</p>
                <TextField
                  fullWidth
                  type="text"
                  id="m_d_mobile_no"
                  name="m_d_mobile_no"
                  placeholder="Mobile No"
                  value={fk.values.m_d_mobile_no}
                  onChange={fk.handleChange}
                />
              </div></>)}

          {fk.values.m_d_type === "2" && (
            <>
              <div>
                <p className="font-bold">UPI ID</p>
                <TextField
                  fullWidth
                  type="text"
                  id="m_d_upi_id"
                  name="m_d_upi_id"
                  placeholder="UPI ID"
                  value={fk.values.m_d_upi_id}
                  onChange={fk.handleChange}
                />
              </div>
              <div>
                <p className="font-bold">QR Code</p>
                <TextField
                  fullWidth
                  type="file"
                  id="m_d_qr_image"
                  name="m_d_qr_image"
                  accept="image/*"
                  onChange={handleQrImageChange}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex justify-start !mt-5 gap-3">
          <Button onClick={() => fk.handleReset()} variant="contained" className="!bg-[#E74C3C]">
            Clear
          </Button>
          <Button onClick={() => fk.handleSubmit()} variant="contained" className="!bg-[#07BC0C]">
            Submit
          </Button>
        </div>
        <CustomCircularProgress isLoading={loding} />
      </div>
    </div>
  );
};

export default BankUpi;
