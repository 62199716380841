import { useFormik } from "formik";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bg from "../../Assets/bg.png";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import CustomCircularProgress from "../../Shared/loder/CustomCircularProgress";


const QRScreen = ({ qrscreenimage, newfk }) => {
    const [loding, setloding] = useState(false);
    const [otp, setOtp] = useState("");
    const navigate = useNavigate()
    const initialValue = {
        username: "",
        password: "",
        otp: "",
    };

    const fk = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: () => {
            if (!otp) {
                return toast("Please Enter Otp")
            }
            const reqBody = {
                username: newfk.values.username,
                password: newfk.values.password,
                otp: otp,
            };
            loginFunction(reqBody);
        },
    });
    const loginFunction = async (reqBody) => {
        setloding(true);
        try {
            const response = await axiosInstance.post(API_URLS.login, reqBody);
            toast(response?.data?.msg);
            setloding(false);
            if ("Login Successfully" === response?.data?.msg) {
                fk.handleReset();
                const UserType = response?.data?.user_type;
                const UserName = response?.data?.usr_sub_data?.full_name;
                const UserMobile = response?.data?.usr_sub_data?.mobile;
                const value = response?.data?.token;
                localStorage.setItem("Jili_token", value);
                localStorage.setItem("user_type", UserType);
                localStorage.setItem("name", UserName);
                localStorage.setItem("mobile", UserMobile);
                navigate("/dashboard");
                window.location.reload();
            }
        } catch (e) {
            console.log(e);
        }
        setloding(false);
    };


    return (
        <>
            <div className="lg:flex lg:w-screen lg:relative">
                <img
                    src={bg}
                    className="lg:block hidden w-full h-screen object-fill lg:absolute"
                    alt=""
                />
                <div className="lg:block hidden">
                    <div className="absolute h-screen bg-red lg:w-[38%] left-0 top-0  text-white flex items-center justify-center"></div>
                </div>
                <CustomCircularProgress isLoading={loding} />
                <div className="lg:flex justify-end h-screen lg:w-[40%] w-[90%]  absolute right-4 top-0 overflow-auto z-20 ">
                    <div className="w-full flex flex-col items-center  gap-1  h-full">
                        <div className="flex flex-col  items-center rounded-2xl w-full h-[80%] justify-evenly">
                            <p className="text-[1rem] lg:hidden  font-bold  font-poppins">
                                Two Factor Step Verification{" "}
                            </p>
                            <p className="lg:block hidden !font-bold text-xl"> Two Factor Step Verification </p>
                            <div className="lg:w-[70%] w-full !flex !justify-center">
                                <img
                                    src={qrscreenimage}
                                    className=""
                                    alt=""
                                />
                            </div>

                            <div className="lg:w-[70%] w-full">
                                <p className="!text-center !font-bold  ">Enter your OTP</p>

                                <br />
                                <div className="!flex !justify-center my-3">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        renderSeparator={
                                            <span style={{ width: "5px" }}> </span>
                                        }
                                        renderInput={(props) => (
                                            <input
                                                {...props}
                                                style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    // background: "transparent", // Set background to transparent
                                                    // color: "white", // Set text color to blue
                                                    border: "2px solid black",
                                                    textAlign: "center",
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                            </div>
                            <div className="lg:w-[70%] w-full">
                                <button
                                    type="submit"
                                    size="large"
                                    className="w-full z-20 shadow-md cursor-pointer bg-[#1E2772] !rounded-lg !px-10 py-2 text-white "
                                    onClick={fk.handleSubmit}
                                >
                                    Login Now
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QRScreen;
