import React, { useEffect, useState } from "react";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";

const CoinList = () => {
  const [loading, setloding] = useState(false);
  const [data, setData] = useState([]);
  const [zoomedImage, setZoomedImage] = useState(null);

  const CoinListFn = async () => {
    setloding(true);
    try {
      const response = await axiosInstance.get(API_URLS.md_coin_own);
      setData(response?.data?.data || []);
      setloding(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    CoinListFn();
  }, []);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  const tablehead = [
    <span>S.No</span>,
    <span>Account Holder Name</span>,
    <span>Bank Name</span>,
    <span>Account No.</span>,
    <span>UPI ID</span>,
    <span>Coin Rate</span>,
    <span>Coin Req.</span>,
    <span>Coin Amount</span>,
    <span>Type</span>,
    <span>Customer ID</span>,
    <span>Receipt</span>,
    <span>Status</span>,
  ];

  const tablerow = data?.map((i, index) => {
    return [
      <span>{index + 1}</span>,
      <span>{i?.m_d_holder_name || "--"}</span>,
      <span>{i?.m_d_bank_name || "--"}</span>,
      <span>{i?.m_d_account_no || "--"}</span>,
      <span>{i?.m_d_upi_id || "--"}</span>,
      <span>{Number(i?.d_r_coin_rate)?.toFixed(2)}</span>,
      <span>{Number(i?.d_r_coins)?.toFixed(2)}</span>,
      <span>{Number(i?.d_r_amount)?.toFixed(2)}</span>,
      <span>{i?.m_d_type}</span>,
      <span>{i?.lo_cust_id}</span>,
      <span>
        <img
          src={i?.d_r_recipt_img}
          alt="receipt"
          className="h-20 w-20 cursor-pointer"
          onClick={() => handleImageClick(i?.d_r_recipt_img)}
        />
      </span>,
      <span>{i?.d_r_payment_status}</span>,
    ];
  });

  return (
    <div>
      <CustomTable tablehead={tablehead} tablerow={tablerow} isLoading={loading} />

      {/* Image zoom section */}
      {zoomedImage && (
        <div
          className="zoomed-image-container"
          onClick={closeZoomedImage}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <img
            src={zoomedImage}
            alt="Zoomed Receipt"
            className="zoomed-image"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CoinList;
