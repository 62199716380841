import { Edit, FilterAlt, Lock } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import { toast } from "react-toastify";

const DistributerReseller = () => {
  const [zoomedImage, setZoomedImage] = useState(null);
    const [loading, setloding] = useState(false)
    const [data, setData] = useState([])
    const initialValue = {
        username: "",
        start_date: "",
        end_date: "",
    }
    const fk = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: () => {
            setloding(true)
            const reqBody = {
                username: fk.values.username,
                start_date: fk.values.start_date,
                end_date: fk.values.end_date,
            }
            DistributerResellerFn(reqBody)
        }
    })
    const DistributerResellerFn = async (reqBody) => {
        setloding(true)
        try {
            const response = await axiosInstance.post(API_URLS.md_coin_list, reqBody)
            setData(response?.data?.data || [])
       
            setloding(false);
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        DistributerResellerFn()
    }, [])

    const handleImageClick = (imageUrl) => {
        setZoomedImage(imageUrl);
      };
    
      const closeZoomedImage = () => {
        setZoomedImage(null);
      }; 

    const changeStatusApprovedRejectFunction = async (id, reqtype) => {
        try {
            const reqBody = {
                t_id: id,
                req_type: reqtype
            }
            const res = await axiosInstance.post(
                API_URLS?.coin_approve_request, reqBody
            );
            if (res) DistributerResellerFn();
            toast(res?.data?.msg);
            console.log(res);
        } catch (e) {
            console.log(e);
        }
    };

    const tablehead = [
        <span>S.No</span>,
        <span>Account Holder Name</span>,
        <span>Bank Name</span>,
        <span>Account No.</span>,
        <span>UPI ID</span>,
        <span>Coin Rate</span>,
        <span>Coin Req.</span>,
        <span>Coin Amount</span>,
        <span>Type</span>,
        <span>Customer ID</span>,
        <span>Receipt</span>,
        <span>Status</span>,
        <span>Action</span>

    ];

    const tablerow = data?.map((i, index) => {
        return [
            <span>{index + 1}</span>,
            <span>{i?.m_d_holder_name || "--"}</span>,
            <span>{i?.m_d_bank_name || "--"}</span>,
            <span>{i?.m_d_account_no || "--"}</span>,
            <span>{i?.m_d_upi_id || "--"}</span>,
            <span>{Number(i?.d_r_coin_rate)?.toFixed(2)}</span>,
            <span>{Number(i?.d_r_coins)?.toFixed(2)}</span>,
            <span>{Number(i?.d_r_amount)?.toFixed(2)}</span>,
            <span>{i?.m_d_type}</span>,
            <span>{i?.lo_cust_id}</span>,
            <span>
        <img
          src={i?.d_r_recipt_img}
          alt="receipt"
          className="h-20 w-20 cursor-pointer"
          onClick={() => handleImageClick(i?.d_r_recipt_img)}
        />
      </span>,
            <span>{i?.d_r_payment_status}</span>,
            <span>
                {i?.d_r_payment_status === "Pending" ?
                    <div className="flex flex-col gap-1">
                        <Button
                            variant="contained"
                            className="!bg-[#198754]"
                            onClick={() => changeStatusApprovedRejectFunction(i?.d_r_id, 1)}
                        >
                            Approve
                        </Button>
                        <Button
                            variant="contained"
                            className="!bg-[#dc3545]"
                            onClick={() => changeStatusApprovedRejectFunction(i?.d_r_id, 2)}
                        >
                            Reject
                        </Button>
                    </div> : <Lock />}
            </span>

            ,

        ];
    });
    return (
        <div>
            <div className="flex px-2 gap-2 !justify-start py-2">
                <span className="!text-xs !text-center">From:</span>
                <TextField
                    size="small"
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={fk.values.start_date}
                    onChange={fk.handleChange}
                />
                <span className="!text-xs !text-center">To:</span>
                <TextField
                    size="small"
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={fk.values.end_date}
                    onChange={fk.handleChange}
                />
                <TextField
                    size="small"
                    type="search"
                    id="username"
                    name="username"
                    placeholder="Search by user id"
                    value={fk.values.username}
                    onChange={fk.handleChange}
                />

                <Button
                    onClick={fk.handleSubmit}
                    variant="contained"
                    startIcon={<FilterAlt />}
                >
                    Filter
                </Button>
            </div>

            <CustomTable
                tablehead={tablehead}
                tablerow={tablerow}
                isLoading={loading}
            />
{zoomedImage && (
        <div
          className="zoomed-image-container"
          onClick={closeZoomedImage}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <img
            src={zoomedImage}
            alt="Zoomed Receipt"
            className="zoomed-image"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
        </div>
    );
};

export default DistributerReseller;
